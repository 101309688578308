import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import { saasModernTheme } from 'common/theme/saasModern';
import { ResetCSS } from 'common/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'containers/SaasModern/sassModern.style';
import Navbar from 'containers/SaasModern/Navbar';
import Ratings from 'containers/Custom/Zoominfo/Ratings';
import ZoomInfoHeader from '../containers/Custom/Landing/Zoominfo/LushaHeader';
import ZoomInfoFeatures from '../containers/Custom/Landing/Zoominfo/Features';
import ZoomInfoBanner from '../containers/Custom/Landing/Zoominfo/InformationBanner';
import PartnerSections from '../containers/Custom/Landing/Zoominfo/Partner';
import Comparison from '../containers/Custom/Landing/Zoominfo/LushaComparison';
import Reviews from '../containers/Custom/Landing/Zoominfo/Reviews';
import Footer from 'containers/AgencyModern/Footer';
import Seo from 'components/seo';
import Directory from '../containers/Directory';
import Thanksgiving from '../containers/SaasModern/Thanksgiving';
import G2Slider from '../containers/SaasModern/G2Badges';
import BookDemoButton from '../containers/SaasModern/ScheduleAdemo/ButtonDemo';

const SalesIntel = () => {
  const ZoominfoPageData = useStaticQuery(graphql`
    {
      allStrapiHomeClientLogo {
        nodes {
          title
          logos {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `);

  const clientLogosData = ZoominfoPageData.allStrapiHomeClientLogo.nodes[0];

  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <Seo
          title="Looking for a Lusha alternative? Choose Clodura.AI"
          description="Looking for a powerful Lusha competitor? Clodura offers accurate contact data and sales intelligence to help you grow your business. Try it today!"
          robots="index, follow"
          canonical="https://www.clodura.ai/lusha-competitor/"
        />
        <ResetCSS />

        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
              {/* <Thanksgiving /> */}
            </DrawerProvider>
          </Sticky>
          <ZoomInfoHeader />
          <PartnerSections {...clientLogosData} />
          <Comparison />
          <ZoomInfoFeatures />
          <ZoomInfoBanner />
          <Reviews />
          <Ratings />
          <G2Slider />
          {/* <Directory /> */}
          <Footer />
          <BookDemoButton />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
export default SalesIntel;
